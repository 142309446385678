import { Toaster } from "@/components/ui/toaster";
import { Toaster as Sonner } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ErrorBoundary from "@/components/ui/ErrorBoundary";
import { useEffect, Suspense, lazy } from "react";
import { checkSupabaseConnection } from "@/lib/supabase";
import { HelmetProvider } from 'react-helmet-async';
import { Analytics } from "@vercel/analytics/react";
import SEO from "@/components/SEO";

// Import the Index page normally since it's the first page users will see
import Index from "./pages/Index";

// Lazy load other pages to improve initial load time
const Examples = lazy(() => import("./pages/Examples"));
const NotFound = lazy(() => import("./pages/NotFound"));
const CustomSong = lazy(() => import("./pages/CustomSong"));
const FAQPage = lazy(() => import("./pages/FAQ"));
const RefundPolicy = lazy(() => import("./pages/RefundPolicy"));
const CookiePolicy = lazy(() => import("./pages/CookiePolicy"));
const TermsOfService = lazy(() => import("./pages/TermsOfService"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));

// Loading fallback component
const LoadingFallback = () => (
  <div className="flex items-center justify-center min-h-screen">
    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-500"></div>
  </div>
);

const App = () => {
  console.log("App component rendering");

  // Check Supabase connection on mount
  useEffect(() => {
    const checkConnection = async () => {
      try {
        const isConnected = await checkSupabaseConnection();
        console.log("Supabase connection status:", isConnected ? "Connected" : "Not connected");
      } catch (error) {
        console.error("Error checking Supabase connection:", error);
      }
    };
    
    checkConnection();
    
    // Hide the loading spinner
    const spinner = document.getElementById('loading-spinner');
    if (spinner) {
      spinner.style.display = 'none';
    }
  }, []);

  return (
    <HelmetProvider>
      <SEO />
      <ErrorBoundary>
        <TooltipProvider>
          <Toaster />
          <Sonner />
          <BrowserRouter>
            <Suspense fallback={<LoadingFallback />}>
              <Routes>
                <Route path="/" element={<Index />} />
                <Route path="/examples" element={<Examples />} />
                <Route path="/create" element={
                  <ErrorBoundary>
                    <CustomSong />
                  </ErrorBoundary>
                } />
                <Route path="/faq" element={<FAQPage />} />
                <Route path="/refund-policy" element={<RefundPolicy />} />
                <Route path="/cookie-policy" element={<CookiePolicy />} />
                <Route path="/terms-of-service" element={<TermsOfService />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                {/* ADD ALL CUSTOM ROUTES ABOVE THE CATCH-ALL "*" ROUTE */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Suspense>
          </BrowserRouter>
          <Analytics />
        </TooltipProvider>
      </ErrorBoundary>
    </HelmetProvider>
  );
};

export default App;
