import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { X } from 'lucide-react';
import { Button } from './button';

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Check if user has already accepted cookies
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    if (!cookiesAccepted) {
      // Show banner after a short delay
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setIsVisible(false);
  };

  const customizeCookies = () => {
    // This would typically open a modal with more detailed cookie settings
    // For now, we'll just redirect to the cookie policy page
    window.location.href = '/cookie-policy';
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 z-50 p-4 bg-white dark:bg-slate-900 shadow-lg border-t border-slate-200 dark:border-slate-800 animate-slide-up">
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between gap-4">
          <div className="flex-1 pr-8">
            <h3 className="text-sm font-semibold text-slate-900 dark:text-white mb-1">
              We use cookies
            </h3>
            <p className="text-xs text-slate-600 dark:text-slate-400">
              We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic. By clicking "Accept All", you consent to our use of cookies. Read our{' '}
              <Link to="/cookie-policy" className="text-brand-500 hover:text-brand-600 underline">
                Cookie Policy
              </Link>{' '}
              to learn more.
            </p>
          </div>
          <div className="flex flex-wrap gap-2">
            <Button 
              variant="outline" 
              size="sm" 
              onClick={customizeCookies}
              className="text-xs"
            >
              Customize
            </Button>
            <Button 
              variant="default" 
              size="sm" 
              onClick={acceptCookies}
              className="text-xs bg-brand-500 hover:bg-brand-600"
            >
              Accept All
            </Button>
          </div>
          <button 
            onClick={() => setIsVisible(false)} 
            className="absolute top-2 right-2 md:static text-slate-500 hover:text-slate-700 dark:text-slate-400 dark:hover:text-slate-300"
            aria-label="Close cookie banner"
          >
            <X size={16} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieBanner; 