import { createClient } from '@supabase/supabase-js';
import type { Database } from '@/integrations/supabase/types';

// Get environment variables
const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

// Log environment variable status
console.log("Checking Supabase configuration...");
console.log("Supabase URL status:", supabaseUrl ? "✓ Found" : "✗ Missing");
console.log("Supabase Anon Key status:", supabaseAnonKey ? "✓ Found" : "✗ Missing");

// Create a Supabase client with enhanced error handling
let supabase;
try {
  if (!supabaseUrl || !supabaseAnonKey) {
    throw new Error('Required Supabase configuration is missing. Please check your environment variables.');
  }
  
  supabase = createClient<Database>(supabaseUrl, supabaseAnonKey, {
    auth: {
      persistSession: true,
      autoRefreshToken: true,
      detectSessionInUrl: true,
      flowType: 'pkce'
    },
    global: {
      headers: {
        'Upgrade-Insecure-Requests': '1',
      },
      fetch: (...args) => {
        const [resource, config] = args;
        return fetch(resource, {
          ...config,
          signal: AbortSignal.timeout(8000), // Increased timeout to 8 seconds
        });
      },
    },
  });
  console.log("✓ Supabase client initialized successfully");
} catch (error) {
  console.error("✗ Failed to initialize Supabase client:", error);
  // Create a mock client that provides clear feedback
  supabase = {
    from: () => ({
      select: () => Promise.resolve({ 
        data: null, 
        error: { message: "Database temporarily unavailable. Your order will be saved locally." } 
      }),
      insert: () => Promise.resolve({ 
        data: null, 
        error: { message: "Database temporarily unavailable. Your order will be saved locally." } 
      }),
      update: () => Promise.resolve({ 
        data: null, 
        error: { message: "Database temporarily unavailable. Your order will be saved locally." } 
      }),
      delete: () => Promise.resolve({ 
        data: null, 
        error: { message: "Database temporarily unavailable. Your order will be saved locally." } 
      }),
    }),
    auth: {
      getSession: () => Promise.resolve({ data: { session: null }, error: null }),
      signOut: () => Promise.resolve({ error: null }),
    },
  };
}

export { supabase };

// Enhanced connection check with better timeout handling
export const checkSupabaseConnection = async (): Promise<boolean> => {
  console.log("Checking database connection...");
  
  return new Promise<boolean>((resolve) => {
    const mainTimeout = setTimeout(() => {
      console.log("❌ Database connection check timed out");
      resolve(false);
    }, 8000); // Increased to 8 seconds
    
    (async () => {
      try {
        const controller = new AbortController();
        const queryTimeout = setTimeout(() => {
          controller.abort();
          console.log("❌ Database query timed out");
        }, 6000); // Increased to 6 seconds
        
        const { error } = await supabase
          .from('custom_songs_orders')
          .select('id')
          .limit(1)
          .abortSignal(controller.signal);
        
        clearTimeout(queryTimeout);
        clearTimeout(mainTimeout);
        
        if (error) {
          console.warn('❌ Database connection check failed:', error.message);
          resolve(false);
        } else {
          console.log('✓ Database connection successful');
          resolve(true);
        }
      } catch (error) {
        clearTimeout(mainTimeout);
        console.warn('❌ Database connection error:', error);
        resolve(false);
      }
    })();
  });
};

// Enhanced safe database operation function
export const safeDbOperation = async <T>(
  operation: () => Promise<T>,
  fallback: T
): Promise<T> => {
  try {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => {
      controller.abort();
      console.log("❌ Database operation timed out");
    }, 8000); // Increased to 8 seconds
    
    const result = await Promise.race([
      operation(),
      new Promise<T>((_, reject) => {
        setTimeout(() => reject(new Error('Operation timed out')), 8000);
      })
    ]);
    
    clearTimeout(timeoutId);
    return result;
  } catch (error) {
    console.warn('❌ Database operation failed:', error);
    return fallback;
  }
};
