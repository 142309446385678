import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

console.log("Main.tsx is executing");

// Function to hide the loading spinner
const hideLoadingSpinner = () => {
  try {
    const spinner = document.getElementById('loading-spinner');
    if (spinner) {
      spinner.style.display = 'none';
      // Clear the app load timeout if it exists
      if (window.APP_LOAD_TIMEOUT) {
        clearTimeout(window.APP_LOAD_TIMEOUT);
      }
    }
  } catch (error) {
    console.error("Error hiding loading spinner:", error);
  }
};

// Create a client with simpler configuration
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000, // 5 minutes
    },
  },
});

// Add error event listener
window.addEventListener('error', (event) => {
  console.error('Global error caught in main.tsx:', event.error);
});

// Add unhandled promise rejection listener
window.addEventListener('unhandledrejection', (event) => {
  console.error('Unhandled Promise Rejection in main.tsx:', event.reason);
});

// Wrap the rendering in a try-catch block
try {
  console.log("Attempting to render React application");
  
  // Find the root element
  const rootElement = document.getElementById('root');
  if (!rootElement) {
    throw new Error("Root element not found");
  }
  
  // Create the root and render the app
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </React.StrictMode>
  );
  
  console.log("React application rendered successfully");
  
  // Hide the loading spinner after a short delay to ensure the app is visible
  setTimeout(hideLoadingSpinner, 100);
} catch (error) {
  console.error("Error rendering React application:", error);
  
  // Try to hide the loading spinner
  hideLoadingSpinner();
  
  // Show an error message
  const rootElement = document.getElementById('root');
  if (rootElement) {
    rootElement.innerHTML = `
      <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; height: 100vh; padding: 20px; text-align: center;">
        <h2 style="color: #e11d48; margin-bottom: 16px; font-size: 24px;">Failed to load application</h2>
        <p style="margin-bottom: 16px; color: #4b5563;">There was an error initializing the application. Please try refreshing the page.</p>
        <div style="background-color: #fee2e2; border: 1px solid #fecaca; border-radius: 4px; padding: 8px; margin-bottom: 16px; max-width: 100%; overflow-x: auto;">
          <code style="font-family: monospace; color: #b91c1c; font-size: 14px;">${error instanceof Error ? error.message : String(error)}</code>
        </div>
        <button onclick="window.location.reload()" style="background-color: #2563eb; color: white; padding: 8px 16px; border: none; border-radius: 4px; cursor: pointer;">
          Refresh Page
        </button>
      </div>
    `;
  }
}

// Add the APP_LOAD_TIMEOUT to the window object
declare global {
  interface Window {
    APP_LOAD_TIMEOUT: number;
  }
}
