import React, { Component, ErrorInfo, ReactNode } from 'react';
import { RefreshCw, AlertTriangle } from 'lucide-react';

interface Props {
  children: ReactNode;
  fallback?: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null,
    errorInfo: null
  };

  public static getDerivedStateFromError(error: Error): Partial<State> {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Log the error to the console
    console.error('Uncaught error in component:', error);
    console.error('Component stack trace:', errorInfo.componentStack);
    
    // Update state with the error info
    this.setState({ errorInfo });
    
    // You could also log the error to an error reporting service here
  }

  private handleRefresh = () => {
    // Reload the current page
    window.location.reload();
  };

  private handleReset = () => {
    // Reset the error state to try rendering the children again
    this.setState({ hasError: false, error: null, errorInfo: null });
  };

  public render() {
    if (this.state.hasError) {
      // If a custom fallback was provided, use it
      if (this.props.fallback) {
        return this.props.fallback;
      }
      
      // Otherwise, use our default error UI
      return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50 dark:bg-gray-900 p-4">
          <div className="max-w-md w-full bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
            <div className="flex items-center mb-4 text-red-600 dark:text-red-400">
              <AlertTriangle className="w-6 h-6 mr-2" />
              <h2 className="text-2xl font-bold">Something went wrong</h2>
            </div>
            
            <div className="bg-red-50 dark:bg-red-900/30 border border-red-200 dark:border-red-800 rounded p-4 mb-4">
              <p className="text-sm text-red-800 dark:text-red-300 font-mono overflow-auto whitespace-pre-wrap">
                {this.state.error?.message || 'An unknown error occurred'}
              </p>
              
              {process.env.NODE_ENV !== 'production' && this.state.errorInfo && (
                <details className="mt-2">
                  <summary className="text-xs text-red-700 dark:text-red-400 cursor-pointer">
                    View component stack trace
                  </summary>
                  <pre className="mt-2 text-xs text-red-700 dark:text-red-400 overflow-auto p-2 bg-red-100 dark:bg-red-900/50 rounded">
                    {this.state.errorInfo.componentStack}
                  </pre>
                </details>
              )}
            </div>
            
            <p className="text-gray-600 dark:text-gray-300 mb-4">
              Please try refreshing the page or resetting the component if the problem persists.
            </p>
            
            <div className="flex space-x-3">
              <button
                onClick={this.handleRefresh}
                className="flex-1 bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded transition-colors flex items-center justify-center"
              >
                <RefreshCw className="w-4 h-4 mr-2" />
                Refresh Page
              </button>
              
              <button
                onClick={this.handleReset}
                className="flex-1 bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600 text-gray-800 dark:text-gray-200 font-medium py-2 px-4 rounded transition-colors"
              >
                Try Again
              </button>
            </div>
          </div>
        </div>
      );
    }

    // If there's no error, render the children
    return this.props.children;
  }
}

export default ErrorBoundary; 