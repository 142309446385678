import React from 'react';
import { Helmet } from 'react-helmet-async';

interface SEOProps {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
  type?: string;
  twitterCard?: string;
  canonical?: string;
  schemaData?: Record<string, any>;
}

const SEO = ({
  title = "SonicGifts - Custom Personalized Songs",
  description = "Create unique personalized songs for your loved ones. The perfect gift for any occasion.",
  image = "/images/og-image.jpg", // Default image path
  url = typeof window !== 'undefined' ? window.location.href : 'https://sonicgifts.com',
  type = "website",
  twitterCard = "summary_large_image",
  canonical,
  schemaData
}: SEOProps) => {
  // Ensure the image URL is absolute
  const imageUrl = image.startsWith('http') 
    ? image 
    : `${typeof window !== 'undefined' ? window.location.origin : 'https://sonicgifts.com'}${image}`;

  return (
    <Helmet>
      {/* Primary Meta Tags */}
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      
      {/* Canonical URL */}
      {canonical && <link rel="canonical" href={`${typeof window !== 'undefined' ? window.location.origin : 'https://sonicgifts.com'}${canonical}`} />}

      {/* Open Graph / Facebook */}
      <meta property="og:type" content={type} />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:site_name" content="SonicGifts" />

      {/* Twitter */}
      <meta property="twitter:card" content={twitterCard} />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={imageUrl} />
      
      {/* Schema.org JSON-LD */}
      {schemaData && (
        <script type="application/ld+json">
          {JSON.stringify(schemaData)}
        </script>
      )}
    </Helmet>
  );
};

export default SEO; 