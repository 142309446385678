import { useState, useRef } from 'react';
import { ChevronLeft, ChevronRight, Quote, Play, Pause } from 'lucide-react';

const testimonials = [
  {
    id: 1,
    content: "I was struggling to find a meaningful gift for my parents' 30th anniversary. The personalized song I created here brought tears to their eyes. It captured their love story perfectly!",
    author: "Emily Johnson",
    role: "Anniversary Gift",
    avatar: "https://randomuser.me/api/portraits/women/32.jpg"
  },
  {
    id: 2,
    content: "My girlfriend couldn't believe I got her a completely custom song for her birthday. The quality was incredible and the lyrics were exactly what I wanted to express. Best gift ever!",
    author: "Michael Chen",
    role: "Birthday Gift",
    avatar: "https://randomuser.me/api/portraits/men/51.jpg"
  },
  {
    id: 3,
    content: "The song created for our wedding first dance was absolutely magical. Our guests were amazed that we had our own custom song, and it made our special day even more memorable.",
    author: "Sarah & David",
    role: "Wedding Gift",
    avatar: "https://randomuser.me/api/portraits/women/44.jpg"
  },
  {
    id: 4,
    content: "I ordered a song to propose to my girlfriend and it worked perfectly! She said yes and we now have a unique song that will forever remind us of that special moment.",
    author: "Thomas Wright",
    role: "Proposal Gift",
    avatar: "https://randomuser.me/api/portraits/men/22.jpg"
  }
];

// Reaction videos data
const reactionVideos = [
  {
    id: 'wife-reaction',
    title: 'Wife\'s Emotional Reaction',
    src: '/videos/wife-reaction.mp4',
    poster: '/videos/thumbnails/wife-reaction-poster.jpg'
  },
  {
    id: 'birthday-reaction',
    title: 'Birthday Surprise Reaction',
    src: '/videos/birthday-song.mp4',
    poster: '/videos/thumbnails/birthday-song-poster.jpg'
  },
  {
    id: 'proposal-reaction',
    title: 'Proposal Moment',
    src: '/videos/marriage-proposal.mp4',
    poster: '/videos/thumbnails/marriage-proposal-poster.jpg'
  },
  {
    id: 'father-reaction',
    title: 'Father\'s Day Reaction',
    src: '/videos/father-day-gift.mp4',
    poster: '/videos/thumbnails/father-day-gift-poster.jpg'
  }
];

// Video Player for reaction videos
const ReactionVideo = ({ src, poster, title }: { src: string; poster: string; title: string }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const togglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div className="aspect-video bg-slate-200 dark:bg-slate-800 rounded-lg overflow-hidden relative group cursor-pointer">
      <video
        ref={videoRef}
        poster={poster}
        className="w-full h-full object-cover"
        onEnded={() => setIsPlaying(false)}
        playsInline
        muted
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
      >
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      
      <div className="absolute inset-0 bg-gradient-to-t from-slate-900/80 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-end p-3">
        <div className="w-full">
          <p className="text-white text-sm font-medium truncate">{title}</p>
          <p className="text-slate-300 text-xs">Watch now</p>
        </div>
      </div>
      
      <div className="absolute inset-0 flex items-center justify-center">
        <button
          onClick={togglePlay}
          className="h-10 w-10 rounded-full bg-white/80 flex items-center justify-center shadow-md transition-transform duration-300 group-hover:scale-110"
          aria-label={isPlaying ? "Pause video" : "Play video"}
        >
          {isPlaying ? 
            <Pause className="w-4 h-4 text-slate-900" /> : 
            <Play className="w-4 h-4 ml-0.5 text-slate-900" />
          }
        </button>
      </div>
    </div>
  );
};

const Testimonials = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  
  const handlePrev = () => {
    setActiveIndex((prev) => (prev === 0 ? testimonials.length - 1 : prev - 1));
  };
  
  const handleNext = () => {
    setActiveIndex((prev) => (prev === testimonials.length - 1 ? 0 : prev + 1));
  };

  return (
    <section className="py-16 sm:py-24 bg-brand-50 dark:bg-slate-800/30 relative overflow-hidden">
      {/* Decorative elements */}
      <div className="absolute top-0 left-0 w-full h-full overflow-hidden pointer-events-none">
        <div className="absolute -top-24 -left-24 w-48 h-48 rounded-full bg-gradient-to-br from-fuchsia-200/40 to-brand-200/40 dark:from-fuchsia-900/20 dark:to-brand-900/20 blur-3xl"></div>
        <div className="absolute top-1/2 right-0 w-72 h-72 rounded-full bg-gradient-to-tr from-brand-200/30 to-fuchsia-200/30 dark:from-brand-900/20 dark:to-fuchsia-900/20 blur-3xl transform -translate-y-1/2"></div>
      </div>
      
      <div className="relative container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center max-w-3xl mx-auto mb-16">
          <h2 className="heading-md mb-4 text-slate-900 dark:text-white">
            What Our Customers <span className="text-gradient">Say</span>
          </h2>
          <p className="text-lg text-slate-600 dark:text-slate-400">
            Discover how our personalized songs have created unforgettable moments for special occasions.
          </p>
        </div>

        <div className="relative max-w-4xl mx-auto">
          {/* Testimonial card */}
          <div className="glass-card rounded-2xl p-6 sm:p-10 transition-all duration-500 ease-in-out transform">
            <div className="flex flex-col items-center">
              <Quote size={48} className="text-brand-300 dark:text-brand-600 opacity-50 mb-6" />
              
              <div className="text-center mb-8">
                <p className="text-xl sm:text-2xl font-medium text-slate-700 dark:text-slate-300 leading-relaxed mb-6">
                  "{testimonials[activeIndex].content}"
                </p>
                
                <div className="flex items-center justify-center">
                  <div className="mr-4">
                    <img 
                      src={testimonials[activeIndex].avatar} 
                      alt={testimonials[activeIndex].author}
                      className="h-14 w-14 rounded-full object-cover border-2 border-white dark:border-slate-700 shadow-md"
                    />
                  </div>
                  <div className="text-left">
                    <p className="font-semibold text-slate-900 dark:text-white">
                      {testimonials[activeIndex].author}
                    </p>
                    <p className="text-sm text-brand-500 dark:text-brand-400">
                      {testimonials[activeIndex].role}
                    </p>
                  </div>
                </div>
              </div>
              
              {/* Navigation */}
              <div className="flex items-center justify-center space-x-3">
                <button 
                  onClick={handlePrev}
                  className="h-10 w-10 rounded-full flex items-center justify-center bg-white dark:bg-slate-700 shadow-md hover:bg-brand-50 dark:hover:bg-slate-600 transition-colors"
                  aria-label="Previous testimonial"
                >
                  <ChevronLeft size={20} className="text-slate-700 dark:text-slate-200" />
                </button>
                
                <div className="flex space-x-2">
                  {testimonials.map((_, idx) => (
                    <button
                      key={idx}
                      onClick={() => setActiveIndex(idx)}
                      className={`h-2.5 rounded-full transition-all duration-300 ${
                        idx === activeIndex ? 'w-8 bg-brand-500' : 'w-2.5 bg-slate-300 dark:bg-slate-600'
                      }`}
                      aria-label={`Go to testimonial ${idx + 1}`}
                    />
                  ))}
                </div>
                
                <button 
                  onClick={handleNext}
                  className="h-10 w-10 rounded-full flex items-center justify-center bg-white dark:bg-slate-700 shadow-md hover:bg-brand-50 dark:hover:bg-slate-600 transition-colors"
                  aria-label="Next testimonial"
                >
                  <ChevronRight size={20} className="text-slate-700 dark:text-slate-200" />
                </button>
              </div>
            </div>
          </div>
          
          {/* Video reactions grid */}
          <div className="mt-16 grid grid-cols-2 sm:grid-cols-4 gap-4 sm:gap-6">
            {reactionVideos.map((video) => (
              <ReactionVideo 
                key={video.id}
                src={video.src}
                poster={video.poster}
                title={video.title}
              />
            ))}
          </div>
          
          <p className="text-sm text-slate-500 dark:text-slate-500 max-w-2xl mx-auto mt-6 italic text-center">
            Note: Some videos displayed are real demos from our customers, while others are sourced from YouTube and publicly available on the internet. We do not claim ownership or rights to these videos and use them for demonstration purposes only.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
