import { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

const faqs = [
  {
    question: "How does the personalized song creation process work?",
    answer: "Our process is simple! First, choose your package and fill out our song creation form with details about the recipient and occasion. Our team of professional musicians and songwriters will then craft a unique song based on your specifications. You'll receive a preview for approval, and after any revisions, we'll deliver the final song in your chosen format."
  },
  {
    question: "How long does it take to create a personalized song?",
    answer: "Creation time depends on the package you select. Our Basic package takes about 7 days, Premium is delivered within 3 days, and Deluxe offers priority 1-day delivery. If you need a song for a specific date, we recommend mentioning this in your order."
  },
  {
    question: "Can I request revisions to my song?",
    answer: "Absolutely! The Basic package includes one round of revisions, while our Premium package offers unlimited revisions and the Deluxe package includes up to 5 revisions until you're completely satisfied with your personalized song. To request a revision, please email contact@sonicgifts.com."
  },
  {
    question: "What music styles are available?",
    answer: "We offer a wide range of styles including Pop, R&B, Acoustic, Rock, Hip-Hop, Country, Folk, Jazz, Electronic, and Classical. If you have a specific style in mind that's not listed, let us know and we'll do our best to accommodate."
  },
  {
    question: "Can I choose the vocalist for my song?",
    answer: "Yes! You can choose between male or female vocalists, and in our Premium and Deluxe packages, you can even select from our roster of different voice types and tones to best match your vision for the song."
  },
  {
    question: "What file formats will I receive my song in?",
    answer: "All files, regardless of the package, are delivered with the lyrics and the MP3 file. The Premium package also includes a lyric video, and Deluxe provides studio-quality WAV files along with a professional music video."
  },
  {
    question: "Can I use the song commercially?",
    answer: "Our Basic and Premium packages provide songs for personal use only. If you need commercial rights, our Deluxe package includes a license for commercial usage. For specific licensing needs, please contact our support team."
  },
  {
    question: "What payment methods do you accept?",
    answer: "We accept all major credit cards, PayPal, and bank transfers. All payments are processed securely through our payment providers with industry-standard encryption."
  }
];

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(0);

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section id="faq" className="py-16 sm:py-24 bg-white dark:bg-slate-900">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center max-w-3xl mx-auto mb-16">
          <h2 className="heading-md mb-4 text-slate-900 dark:text-white">
            Frequently Asked <span className="text-gradient">Questions</span>
          </h2>
          <p className="text-lg text-slate-600 dark:text-slate-400">
            Everything you need to know about our personalized song creation service.
          </p>
        </div>

        <div className="max-w-3xl mx-auto">
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div 
                key={index}
                className={`rounded-xl border ${
                  openIndex === index 
                    ? 'border-brand-300 dark:border-brand-700 shadow-md shadow-brand-100 dark:shadow-none' 
                    : 'border-slate-200 dark:border-slate-800'
                } overflow-hidden transition-all duration-200`}
              >
                <button
                  className="flex justify-between items-center w-full p-6 text-left focus:outline-none"
                  onClick={() => toggleFAQ(index)}
                  aria-expanded={openIndex === index}
                >
                  <span className="text-lg font-medium text-slate-900 dark:text-white">{faq.question}</span>
                  <span className="ml-6 flex-shrink-0 text-brand-500">
                    {openIndex === index ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                  </span>
                </button>
                
                <div 
                  className={`overflow-hidden transition-all duration-300 ease-in-out ${
                    openIndex === index ? 'max-h-96' : 'max-h-0'
                  }`}
                >
                  <div className="p-6 pt-0 text-slate-600 dark:text-slate-400">
                    {faq.answer}
                  </div>
                </div>
              </div>
            ))}
          </div>
          
          <div className="mt-12 text-center">
            <p className="text-slate-600 dark:text-slate-400 mb-6">
              Still have questions? We're here to help!
            </p>
            <a 
              href="mailto:contact@sonicgifts.com" 
              className="inline-flex items-center justify-center btn-primary"
            >
              Contact Support
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
