import { Link } from 'react-router-dom';
import { Music, Play, Gift } from 'lucide-react';
import VideoPlayer from '@/components/ui/VideoPlayer';
const Hero = () => {
  return <section className="relative py-20 sm:py-32 overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-b from-brand-50/70 via-fuchsia-50/30 to-white dark:from-brand-950/20 dark:via-fuchsia-950/10 dark:to-slate-900"></div>
      
      <div className="absolute top-1/4 right-[10%] w-64 h-64 bg-gradient-to-tr from-fuchsia-500/10 to-brand-500/10 rounded-full blur-3xl animate-float"></div>
      <div className="absolute bottom-1/4 left-[5%] w-72 h-72 bg-gradient-to-br from-brand-400/10 to-fuchsia-400/10 rounded-full blur-3xl animate-float animation-delay-500"></div>
      
      <div className="relative container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <div className="text-center lg:text-left px-4 py-6">
            <div className="inline-flex items-center px-3 py-1 rounded-full bg-brand-100 dark:bg-brand-900/30 text-brand-600 dark:text-brand-300 text-xs font-medium mb-6 animate-fade-in">
              <Music size={14} className="mr-1.5" />
              <span>The perfect gift for any occasion</span>
            </div>
            
            <h1 className="heading-lg mb-4 animate-fade-in">
              <span className="text-gradient block mb-2 hero-heading-text">Gift a Personalized Song</span>
              <span className="block text-slate-900 dark:text-white mt-1 hero-heading-text">A Unique & Unforgettable Present!</span>
            </h1>
            
            <p className="text-lg sm:text-xl text-slate-700 dark:text-slate-300 mb-8 max-w-2xl mx-auto lg:mx-0 animate-fade-in animate-delay-100">
              A gift no one has ever offered before. Create a personalized song for a special moment that will be cherished forever.
            </p>
            
            <div className="flex flex-col sm:flex-row gap-4 justify-center lg:justify-start animate-fade-in animate-delay-200">
              <Link to="/create" className="btn-primary inline-flex items-center justify-center">
                <Music className="mr-2" size={18} />
                Create Your Song Now
              </Link>
              <Link to="/examples" className="btn-secondary inline-flex items-center justify-center">
                <Play className="mr-2" size={18} />
                Listen to Examples
              </Link>
            </div>
            
            <div className="mt-8 pt-8 border-t border-slate-200 dark:border-slate-800 flex items-center justify-center lg:justify-start space-x-8 animate-fade-in animate-delay-300">
              <div className="flex items-center">
                <svg className="h-5 w-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
                <svg className="h-5 w-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
                <svg className="h-5 w-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
                <svg className="h-5 w-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
                <svg className="h-5 w-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
                <span className="ml-2 text-sm font-medium text-slate-700 dark:text-slate-300">
                  4.9/5 from 500+ reviews
                </span>
              </div>
              
              <div className="text-sm text-slate-700 dark:text-slate-300 font-medium">
                <span className="text-brand-500">8,000+</span> songs created
              </div>
            </div>
          </div>
          
          <div className="relative">
            <div className="absolute inset-0 bg-gradient-to-r from-brand-500/20 to-fuchsia-500/20 rounded-3xl blur-md transform scale-105"></div>
            <div className="relative bg-white dark:bg-slate-800 rounded-3xl shadow-xl overflow-hidden border border-white/50 dark:border-slate-700/50">
              <div className="aspect-video relative overflow-hidden">
                <VideoPlayer src="/videos/wife-reaction.mp4" poster="/videos/thumbnails/wife-reaction-poster.jpg" loop />
              </div>
              
              <div className="p-6">
                <div className="flex items-start justify-between">
                  <div>
                    <h3 className="text-lg font-semibold text-slate-900 dark:text-white">Marriage Anniversary Gift</h3>
                    <p className="text-sm text-slate-600 dark:text-slate-400 mt-1">Tears of joy as she realizes that this is the most meaningful gift she's ever received</p>
                  </div>
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-brand-100 dark:bg-brand-900/30 text-brand-800 dark:text-brand-300">
                    <Gift size={12} className="mr-1" />
                    Popular Gift
                  </span>
                </div>
                
                <div className="mt-4 flex items-center">
                  <div className="flex-1 bg-slate-200 dark:bg-slate-700 rounded-full h-1.5">
                    <div className="bg-brand-500 h-1.5 rounded-full w-3/4"></div>
                  </div>
                  <span className="ml-3 text-xs font-medium text-slate-600 dark:text-slate-400">2:34</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>;
};
export default Hero;
