import { Button } from "@/components/ui/button";
import Hero from "@/components/home/Hero";
// import Packages from "@/components/home/Packages"; // Removed packages component
import Testimonials from "@/components/home/Testimonials";
import VideoExamples from "@/components/home/VideoExamples";
import FAQ from "@/components/home/FAQ";
import { Link } from "react-router-dom";
import Navbar from "@/components/layout/Navbar";
import Footer from "@/components/layout/Footer";
import CookieBanner from "@/components/ui/CookieBanner";
import SEO from "@/components/SEO";
import { ArrowRight, Music, Heart, Gift } from "lucide-react";

// Declare global gtag function for analytics
declare global {
  interface Window {
    gtag?: (
      command: string,
      action: string,
      params?: Record<string, unknown>
    ) => void;
  }
}

const Index = () => {
  // Schema.org structured data for the homepage
  const schemaData = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "SonicGifts",
    "url": window.location.origin,
    "potentialAction": {
      "@type": "SearchAction",
      "target": `${window.location.origin}/search?q={search_term_string}`,
      "query-input": "required name=search_term_string"
    },
    "description": "Create unique personalized songs for your loved ones. The perfect gift for any occasion.",
    "publisher": {
      "@type": "Organization",
      "name": "Ecom Capital Ventures",
      "logo": {
        "@type": "ImageObject",
        "url": `${window.location.origin}/logo.png`
      }
    }
  };

  return (
    <div className="min-h-screen bg-background">
      <SEO 
        title="SonicGifts - Personalized Song Gifts for Any Occasion"
        description="Create unique, personalized songs for birthdays, anniversaries, weddings, and more. The perfect gift that will be remembered forever."
        canonical="/"
        schemaData={schemaData}
      />
      <Navbar />
      
      <main>
        <Hero />
        
        {/* New Call to Action Section */}
        <section className="py-10 relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-r from-brand-50 to-fuchsia-50 dark:from-brand-950/30 dark:to-fuchsia-950/30"></div>
          
          {/* Decorative elements */}
          <div className="absolute top-1/2 left-1/4 w-64 h-64 bg-gradient-to-br from-brand-500/5 to-fuchsia-500/5 rounded-full blur-3xl"></div>
          <div className="absolute bottom-0 right-1/4 w-72 h-72 bg-gradient-to-tr from-fuchsia-400/5 to-brand-400/5 rounded-full blur-3xl"></div>
          
          <div className="container mx-auto px-4 relative">
            <div className="max-w-4xl mx-auto bg-white dark:bg-slate-800/90 rounded-2xl shadow-xl overflow-hidden">
              <div className="flex flex-col md:flex-row">
                <div className="md:w-2/3 p-8 md:p-10">
                  <div className="inline-flex items-center px-3 py-1 rounded-full bg-brand-100 dark:bg-brand-900/30 text-brand-600 dark:text-brand-300 text-xs font-medium mb-4">
                    <Gift size={14} className="mr-1.5" />
                    <span>50% OFF Limited Time Offer</span>
                  </div>
                  
                  <h2 className="text-2xl md:text-3xl font-bold text-slate-900 dark:text-white">
                    Create a <span className="text-gradient">memorable gift</span> in minutes
                  </h2>
                  
                  <p className="mt-3 text-slate-600 dark:text-slate-300">
                    Our team of professional musicians will craft a personalized song just for your special someone.
                    Perfect for birthdays, anniversaries, or any occasion!
                  </p>
                  
                  <div className="mt-6 flex flex-wrap gap-3">
                    <div className="flex items-center">
                      <div className="bg-green-100 dark:bg-green-900/30 p-1.5 rounded-full text-green-600 dark:text-green-400">
                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                        </svg>
                      </div>
                      <span className="ml-2 text-sm text-slate-700 dark:text-slate-300">100% Satisfaction Guarantee</span>
                    </div>
                    
                    <div className="flex items-center">
                      <div className="bg-brand-100 dark:bg-brand-900/30 p-1.5 rounded-full text-brand-600 dark:text-brand-400">
                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                        </svg>
                      </div>
                      <span className="ml-2 text-sm text-slate-700 dark:text-slate-300">Fast Delivery (3-7 Days)</span>
                    </div>
                    
                    <div className="flex items-center">
                      <div className="bg-purple-100 dark:bg-purple-900/30 p-1.5 rounded-full text-purple-600 dark:text-purple-400">
                        <Heart className="w-4 h-4" />
                      </div>
                      <span className="ml-2 text-sm text-slate-700 dark:text-slate-300">Created With Love</span>
                    </div>
                  </div>
                </div>
                
                <div className="md:w-1/3 bg-gradient-to-br from-brand-500 to-fuchsia-600 p-8 md:p-10 flex flex-col justify-center items-center text-white">
                  <span className="text-lg font-medium mb-1 text-white/90">Starting at just</span>
                  <div className="flex items-baseline">
                    <span className="text-4xl font-bold">$19</span>
                    <span className="text-xl">.99</span>
                  </div>
                  <span className="text-sm line-through opacity-75 mb-6">$39.99</span>
                  
                  <Link to="/create" className="w-full">
                    <Button 
                      className="w-full bg-white hover:bg-white/90 text-brand-600 font-medium shadow-lg"
                      data-tracking="home-cta-create-song"
                      aria-label="Create your personalized song"
                      onClick={() => {
                        // Log click event for analytics
                        console.log('CTA button clicked - redirecting to create page');
                        
                        // Optional: Track event with analytics tools
                        try {
                          if (window.gtag) {
                            window.gtag('event', 'click', {
                              'event_category': 'cta',
                              'event_label': 'home-cta-create-song'
                            });
                          }
                        } catch (e) {
                          console.error('Analytics error:', e);
                        }
                      }}
                    >
                      Create Your Song
                      <ArrowRight className="ml-2 h-4 w-4" />
                    </Button>
                  </Link>
                  
                  <p className="mt-4 text-xs text-center text-white/80">
                    Limited time offer. No credit card required to start.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <VideoExamples />
        <Testimonials />
        <FAQ />
        
        <section className="py-20 bg-gradient-primary text-white text-center">
          <div className="container px-4 mx-auto">
            <h2 className="text-3xl md:text-4xl font-bold mb-6">Ready to create your personalized song?</h2>
            <p className="text-lg md:text-xl mb-8 max-w-2xl mx-auto">
              Give a gift that will be remembered forever. Start creating your custom song now!
            </p>
            <Link to="/create">
              <Button size="lg" className="bg-white text-primary hover:bg-white/90 text-lg px-8 py-6 rounded-full">
                Create Your Song Now 🎵
              </Button>
            </Link>
          </div>
        </section>
      </main>
      
      <Footer />
      <CookieBanner />
    </div>
  );
};

export default Index;
