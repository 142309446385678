import VideoPlayer from '@/components/ui/VideoPlayer';
import { Link } from 'react-router-dom';
import { Music } from 'lucide-react';
import { Button } from '@/components/ui/button';

// Video data
const videoExamples = [
  {
    id: 'birthday-song',
    title: 'Personalized Birthday Song',
    description: 'A special song created for Sarah\'s 30th birthday',
    src: '/videos/birthday-song.mp4',
    poster: '/videos/thumbnails/birthday-song-poster.jpg',
  },
  {
    id: 'father-day-gift',
    title: 'Father\'s Day Tribute',
    description: 'A heartfelt song to celebrate an amazing dad',
    src: '/videos/father-day-gift.mp4',
    poster: '/videos/thumbnails/father-day-gift-poster.jpg',
  },
  {
    id: 'wife-gifts-song',
    title: 'Anniversary Surprise',
    description: 'Wife gifts a personalized song to her husband',
    src: '/videos/wife-gifts-song-to-husband.mp4',
    poster: '/videos/thumbnails/wife-gifts-song-to-husband-poster.jpg',
  },
  {
    id: 'marriage-proposal',
    title: 'Perfect Proposal',
    description: 'A beautiful song that led to a "yes!"',
    src: '/videos/marriage-proposal.mp4',
    poster: '/videos/thumbnails/marriage-proposal-poster.jpg',
  }
];

// Example Video Card component
const VideoCard = ({ video }: { video: typeof videoExamples[0] }) => {
  return (
    <div className="bg-white dark:bg-slate-800 rounded-xl overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300">
      <div className="relative">
        <div className="aspect-video overflow-hidden">
          <img 
            src={video.poster} 
            alt={video.title}
            className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
          />
          <div className="absolute inset-0 bg-black/20 flex items-center justify-center">
            <VideoPlayer 
              src={video.src} 
              poster={video.poster}
              className="w-full h-full" 
            />
          </div>
        </div>
      </div>
      
      <div className="p-4">
        <h3 className="font-semibold text-slate-900 dark:text-white text-lg">{video.title}</h3>
        <p className="text-slate-600 dark:text-slate-400 text-sm mt-1">{video.description}</p>
      </div>
    </div>
  );
};

const VideoExamples = () => {
  return (
    <section id="examples" className="py-16 bg-slate-50 dark:bg-slate-900/50">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="heading-md mb-4">
            <span className="text-slate-900 dark:text-white">Listen to Our </span>
            <span className="text-gradient">Music Magic</span>
          </h2>
          <p className="text-lg text-slate-600 dark:text-slate-400 max-w-2xl mx-auto">
            Experience the emotional impact of our personalized songs. Each creation is tailored to tell your unique story and celebrate important moments.
          </p>
          <p className="text-sm text-slate-500 dark:text-slate-500 max-w-2xl mx-auto mt-2 italic">
            Note: Some videos displayed are real demos from our customers, while others are sourced from YouTube and publicly available on the internet. We do not claim ownership or rights to these videos and use them for demonstration purposes only.
          </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {videoExamples.map((video) => (
            <VideoCard key={video.id} video={video} />
          ))}
        </div>
        
        <div className="mt-12 text-center">
          <Link to="/examples">
            <Button variant="outline" className="bg-white dark:bg-slate-800 hover:bg-brand-50 dark:hover:bg-slate-700">
              <Music className="mr-2" size={16} />
              View More Examples
            </Button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default VideoExamples;
